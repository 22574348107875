export const CHECK_UP_PRICE_IN_CENTS_TTC = 360000
export const CHECK_UP_PRICE_IN_CENTS_HT = 300000

export const CHECK_UP_LOCATION = {
  label: 'Zoī Vendōme',
  address: '12, rue Volney 75002 Paris',
  link: 'https://maps.app.goo.gl/ap5SeEtppMs3J7zL8'
}

export const SUPPORT_PHONE_NUMBER = '+33 6 58 53 33 53'
